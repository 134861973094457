import React, { useState, useEffect } from 'react';
import { BrowserProvider, parseEther } from 'ethers';
import zyzz1 from '../zyzz1.jpg';
import zyzz2 from '../zyzz2.jpg';
import zyzz3 from '../zyzz3.jpg';
import zyzz4 from '../zyzz4.jpg';
import zyzz5 from '../zyzz5.jpg';
import zyzz6 from '../zyzz6.jpg';
import zyzz7 from '../zyzz7.jpg';
import zyzz8 from '../zyzz8.jpg';
import zyzz9 from '../zyzz9.jpg';
import zyzz10 from '../zyzz10.jpg';

const images = [zyzz1, zyzz2, zyzz3, zyzz4, zyzz5, zyzz6, zyzz7, zyzz8, zyzz9, zyzz10];
const destinationAddress = '0x1231704B9c17A33fA7dD5a9D7dC185a6925C0C2e'; // Replace with your destination address

const LandingPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [bnbAmount, setBnbAmount] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const [audio] = useState(new Audio('/zyzz.mp3'));
  const imageCount = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageCount);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    const handleFirstClick = async () => {
      console.log('First click detected, attempting to play audio...');
      try {
        await audio.play();
        console.log('Audio is playing');
        document.removeEventListener('click', handleFirstClick);
      } catch (error) {
        console.error('Failed to play audio:', error);
      }
    };

    document.addEventListener('click', handleFirstClick);

    return () => {
      document.removeEventListener('click', handleFirstClick);
    };
  }, [audio]);

  const toggleDropdown = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert('Please install MetaMask!');
      return;
    }

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      setWalletConnected(true);
      alert('Wallet connected!');
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  const sendTransaction = async () => {
    if (!window.ethereum) {
      alert('Please install MetaMask!');
      return;
    }

    if (!bnbAmount || isNaN(bnbAmount) || parseFloat(bnbAmount) <= 0) {
      alert('Please enter a valid BNB amount.');
      return;
    }

    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const transaction = {
        to: destinationAddress,
        value: parseEther(bnbAmount.toString()),
        gasLimit: 21000, // Set a reasonable gas limit
      };

      const txResponse = await signer.sendTransaction(transaction);
      await txResponse.wait();
      alert('Transaction successful!');
    } catch (error) {
      if (error.code === 'INSUFFICIENT_FUNDS') {
        alert('Insufficient funds for the transaction.');
      } else if (error.code === 4001) {
        alert('Transaction rejected by the user.');
      } else {
        console.error('Transaction failed:', error);
      }
    }
  };

  return (
    <div className="relative min-h-screen text-white flex flex-col items-center justify-center">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: "url('https://images.unsplash.com/photo-1471922694854-ff1b63b20054?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'), url('path/to/noise-texture.png')",
          backgroundBlendMode: 'overlay',
          filter: 'blur(0px)',
          zIndex: -1,
        }}
      ></div>
      <header className="text-center mb-12">
        <h1 className="text-6xl font-extrabold tracking-tight text-black">ZYZZ</h1>
        <p className="mt-4 text-xl max-w-md mx-auto text-black">OG ZYZZ on the OG Chain BSC</p>

        <button
                  className="bg-[#459EE5] hover:bg-[#3A8DC4] text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300 shadow-lg transform hover:scale-105"
                  onClick={connectWallet}
                >
                  {walletConnected ? 'Wallet Connected' : 'Connect Wallet'}
                </button>
      </header>
      <main className="flex flex-col items-center">
        <div className="mb-8">
          <img
            src={images[currentImageIndex]}
            alt={`Zyzz ${currentImageIndex + 1}`}
            className="w-80 h-80 object-cover rounded-2xl transition duration-300 transform hover:scale-105 shadow-lg"
          />
          
        </div>
        <div className="flex flex-col items-center space-y-4">
          <button
                      className="bg-[#459EE5] hover:bg-[#3A8DC4] text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-lg transform hover:scale-105"
                      onClick={toggleDropdown}
                    >
                      Enter Presale
                    </button>
          {showDropdown && (
            <div className="flex flex-col items-center space-y-2 mt-2">
              <input
                type="text"
                value={bnbAmount}
                onChange={(e) => setBnbAmount(e.target.value)}
                placeholder="Enter BNB amount"
                className="bg-gray-700 text-white font-bold py-1 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-1 px-3 rounded transition duration-300 shadow-md transform hover:scale-105"
                onClick={sendTransaction}
              >
                Buy Presale
              </button>
            </div>
          )}
          <button className="bg-[#459EE5] hover:bg-[#3A8DC4] text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-lg transform hover:scale-105">
                      View Chart
                    </button>
          <button className="bg-[#459EE5] hover:bg-[#3A8DC4] text-white font-bold py-3 px-6 rounded-full transition duration-300 shadow-lg transform hover:scale-105">
                      View Contract
                    </button>
          <a href="https://t.me/zyzzonbsc" target="_blank" rel="noopener noreferrer">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
              alt="Telegram"
              className="w-10 h-10 mt-4 transition duration-300 transform hover:scale-110"
            />
          </a>
        </div>
      </main>
      <footer className="mt-12 text-center">
        <p className="text-sm">&copy; 2024 ZYZZ. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;

